<template>
  <div class="relative">
        <h2 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          A better way to say "Thanks!"
        </h2>
        <p class="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
          This application lets you say thanks on the blockchain.  <br>
          That means it's permanent, verifiable, and even comes with tips built-in
        </p>
      </div>
</template>

<script>
export default {

}
</script>

<style>

</style>